import React from 'react';

export function ShoppingIcon(props) {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.00007 4.50049V6.5H5.00007V4.50049C5.00007 2.328 6.78443 0.500488 9.00007 0.500488C11.2157 0.500488 13.0001 2.328 13.0001 4.50049V6.5H11.0001V4.50049C11.0001 3.41952 10.0981 2.50049 9.00007 2.50049C7.90201 2.50049 7.00007 3.41952 7.00007 4.50049Z"
        fill="#111111"
      />
      <path
        d="M0.215582 16.2349L0.864487 6.50098H2.86893L2.21115 16.3679C2.1342 17.5223 3.0498 18.501 4.20672 18.501H13.7934C14.9503 18.501 15.8659 17.5223 15.789 16.3679L15.1311 6.50098H17.1355L17.7845 16.2349C17.9385 18.5436 16.1073 20.501 13.7934 20.501H4.20672C1.89287 20.501 0.0616726 18.5436 0.215582 16.2349Z"
        fill="#111111"
      />
    </svg>
  );
}
