import styled from 'styled-components';

import Image from '@nubank/www-latam-commons/components/Image/Image';

export const StyledImage = styled(Image)`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
`;
