import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';

export const StyledBox = styled(Box)`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
