import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

function ImageBlock({ srcSet, ...props }) {
  return (
    <Box
      gridColumn={{ xs: '1 / span 4', md: '1 / span 4', lg: '5 / span 4' }}
      gridRow={{ xs: '2 / span 2', md: '2', lg: '1' }}
      margin={{ xs: '0 2rem', md: '0 0 2rem', lg: '0' }}
      alignSelf={{ lg: 'stretch' }}
      backgroundImage={srcSet}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition={{ xs: 'center top', md: 'top', lg: 'center' }}
      {...props}
    />
  );
}

ImageBlock.defaultProps = {
  srcSet: undefined,
};

ImageBlock.propTypes = {
  srcSet: PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.object])),
};

export default ImageBlock;
