export const topicsList = [
  {
    title: 'Pix no crédito',
    description:
      'Faça pagamentos Pix utilizando seu cartão de crédito. Parcele e aproveite essa vantagem.',
    iconName: 'CalendarIcon',
    id: 13,
  },
  {
    title: 'Pagamento de Boleto com cartão de crédito',
    description: 'Pague seus boletos no crédito e parcele em até 12 vezes.',
    iconName: 'BarcodeIcon',
    id: 14,
  },
  {
    title: 'Parcelamento de Compras',
    description:
      'Comprou e a loja não parcelou? O Nu parcela a compra para você! Simples e prático, direto na sua fatura.',
    iconName: 'InstallmentsIcon',
    id: 15,
  },
];
