import React from 'react';

export function CardIcon(props) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 8L10 5L8 5L8 8H10Z" fill="#111111" />
      <path
        d="M12 3.49691e-07L4 0C1.79086 -9.65645e-08 6.21101e-07 1.79086 5.24537e-07 4L0 16C-9.65645e-08 18.2091 1.79086 20 4 20H12C14.2091 20 16 18.2091 16 16V4C16 1.79086 14.2091 4.46256e-07 12 3.49691e-07ZM14 4L14 16C14 17.1046 13.1046 18 12 18H4C2.89543 18 2 17.1046 2 16L2 4C2 2.89543 2.89543 2 4 2L12 2C13.1046 2 14 2.89543 14 4Z"
        fill="#111111"
      />
    </svg>
  );
}
