import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import ImagePreload, { prepareImagePreload } from '@nubank/www-latam-commons/components/ImagePreload/ImagePreload';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';
import ShortForm from 'patterns/ShortForm/ShortForm';
import ProductPageOpenFormSection from 'patterns/ProductPageOpenFormSection/ProductPageOpenFormSection';
import RegistrationUnavailableLanguage from 'patterns/RegistrationUnavailableLanguage/RegistrationUnavailableLanguage';
import useOpenFormVisibility from 'utils/hooks/useOpenFormVisibility/useOpenFormVisibility';
import { DEFAULT_LOCALE } from 'utils/i18nUtils';

import { StyledImage } from './styles/StyledImage';

function SectionForm({ onFormVisibilityChange }) {
  const intl = useIntl();
  const [ref, isFormHidden] = useOpenFormVisibility();
  const { formatMessage } = useContext(NuDsContext);
  const imageSrcSet = generateDPRsForCMSImage(formatMessage({ id: 'cms.homePage.homeHeroImage' }));
  const optimizedImageSrcSet = {
    ...imageSrcSet,
    lg: {
      '1x': `${imageSrcSet.lg['1x']}?w=1920`,
      '2x': `${imageSrcSet.lg['2x']}?w=1920`,
      '3x': `${imageSrcSet.lg['3x']}?w=1920`,
    },
  };

  useEffect(() => {
    onFormVisibilityChange(isFormHidden);
  }, [isFormHidden]);

  return (
    <>
      <ImagePreload srcSet={prepareImagePreload(optimizedImageSrcSet)} />
      <ProductPageOpenFormSection
        ref={ref}
        className="SectionForm"
        tag="section"
        backgroundColor="primary.default"
      >
        <StyledImage
          srcSet={optimizedImageSrcSet}
          altIntlKey="cms.requestNuPage.heroImage[0].imageMobile.alt"
        />
        <ProductPageOpenFormSection.Container
          gridTemplateRows={{
            xs: '14rem auto 6rem 1fr',
            md: '1fr',
          }}
        >
          <ProductPageOpenFormSection.Heading
            zIndex="1"
            paddingLeft={{ xs: '4x' }}
            gridColumn={{
              xs: '1 / span 4',
              lg: '2 / span 6',
              xl: '1 / span 5',
            }}
            gridRow={{
              xs: '2',
              md: 'initial',
            }}
          >
            <Typography
              variant="heading2"
              tag="h1"
              color="white"
              marginBottom="4x"
              intlKey="cms.homePage.homeHeroTitle"
              whiteSpace="pre-line"
            />
            <Typography
              variant="heading4"
              tag="h2"
              color="white"
              marginBottom="4x"
              intlKey="cms.homePage.homeHeroDescription"
            />
          </ProductPageOpenFormSection.Heading>
          <ProductPageOpenFormSection.FormBlock>
            {intl.locale === DEFAULT_LOCALE ? (
              <ShortForm />
            ) : (
              <RegistrationUnavailableLanguage isOpenForm isShortForm />
            )}
          </ProductPageOpenFormSection.FormBlock>
        </ProductPageOpenFormSection.Container>
      </ProductPageOpenFormSection>
    </>
  );
}

SectionForm.propTypes = {
  onFormVisibilityChange: PropTypes.func.isRequired,
};

export default React.memo(SectionForm);
