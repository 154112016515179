import React from 'react';

export function AccountIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5 12H8V10H5V12Z" fill="#111111" />
      <path
        d="M4 0C1.79086 0 0 1.79086 0 4V18L18 18V4C18 1.79086 16.2091 0 14 0H4ZM2 6V4C2 2.89543 2.89543 2 4 2H5V6H2ZM11 2V6H7V2L11 2ZM2 8L16 8V16H13V10H11V16H2L2 8ZM13 6V2H14C15.1046 2 16 2.89543 16 4V6H13Z"
        fill="#111111"
      />
    </svg>
  );
}
