import React from 'react';

export function PixIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8282 2.10053C13.2661 0.538431 10.7334 0.538431 9.17135 2.10053L2.10028 9.1716C0.538187 10.7337 0.538187 13.2664 2.10028 14.8284L9.17135 21.8995C10.7334 23.4616 13.2661 23.4616 14.8282 21.8995L21.8993 14.8285C23.4614 13.2664 23.4614 10.7337 21.8993 9.1716L14.8282 2.10053ZM15.9263 6.02704C15.279 6.11453 14.6739 6.41167 14.2069 6.87869L11.9998 9.08579L9.79267 6.87869C9.32565 6.41167 8.72052 6.11453 8.07327 6.02704L10.5856 3.51474C11.3666 2.73369 12.6329 2.73369 13.414 3.51474L15.9263 6.02704ZM17.8993 8.00001L20.4851 10.5858C21.2661 11.3669 21.2661 12.6332 20.4851 13.4142L17.8993 16H16.3282C16.063 16 15.8086 15.8946 15.6211 15.7071L13.414 13.5C12.6329 12.719 11.3666 12.719 10.5856 13.5L8.37846 15.7071C8.19092 15.8946 7.93657 16 7.67135 16H6.10027L3.5145 13.4142C2.73345 12.6332 2.73345 11.3669 3.5145 10.5858L6.1003 8.00001H7.67135C7.93657 8.00001 8.19092 8.10536 8.37846 8.2929L10.5856 10.5C11.3666 11.2811 12.6329 11.2811 13.414 10.5L15.6211 8.2929C15.8086 8.10536 16.063 8.00001 16.3282 8.00001H17.8993ZM10.5856 20.4853L8.07324 17.973C8.7205 17.8855 9.32565 17.5884 9.79267 17.1213L11.9998 14.9142L14.2069 17.1213C14.6739 17.5884 15.2791 17.8855 15.9263 17.973L13.414 20.4853C12.6329 21.2664 11.3666 21.2664 10.5856 20.4853Z"
        fill="#111111"
      />
    </svg>
  );
}
