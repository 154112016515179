import React from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';

import { localizeRoute } from 'utils/i18nUtils';
import { createForceRefreshHandler } from 'utils/redirectUtils';

import { Content } from './style';

function RegistrationUnavailableLanguage({
  isOpenForm,
  isShortForm,
  router: { pathname },
}) {
  return (
    <Content isOpenForm={isOpenForm} isShortForm={isShortForm}>
      <Typography
        variant="heading4"
        marginBottom="8x"
        intlKey="COMMON.WIDGET.UNAVAILABLE_LANGUAGE.TITLE"
      />
      <Typography
        variant="paragraph1"
        strong
        intlKey="COMMON.WIDGET.UNAVAILABLE_LANGUAGE.TEXT"
        intlValues={{
          link: (
            <Link
              intlKey="COMMON.WIDGET.UNAVAILABLE_LANGUAGE.TEXT_LINK"
              color="primary"
              typographyProps={{
                variant: 'paragraph1',
                strong: true,
              }}
              href={pathname}
              locale="pt-BR"
              hrefLang="pt-BR"
              id="registration-unavailable-language"
              onClick={e => {
                const route = localizeRoute(pathname, 'pt-BR');
                createForceRefreshHandler(route)(e);
              }}
            />
          ),
        }}
      />
    </Content>
  );
}
RegistrationUnavailableLanguage.defaultProps = {
  isOpenForm: false,
  isShortForm: false,
};

RegistrationUnavailableLanguage.propTypes = {
  isOpenForm: PropTypes.bool,
  isShortForm: PropTypes.bool,
  router: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(RegistrationUnavailableLanguage);
