import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

function HeadingBlock({ children, ...props }) {
  return (
    <Box
      gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 4' }}
      gridRow={{ xs: '1' }}
      {...props}
    >
      {children}
    </Box>
  );
}

HeadingBlock.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeadingBlock;
