import React, { useEffect, useState } from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import { useBreakpointsMediaDown } from '@nubank/nuds-web/styles/breakpoints';

import partition from '@nubank/www-latam-commons/utils/array/partition';
import InfiniteCarousel from 'components/InfiniteCarousel/InfiniteCarousel';

import { benefitsList } from './components/BenefitsList';
import { UnionIcon } from './components/icons/UnionIcon';
import { FinancialIcon } from './components/icons/FinancialIcon';
import { CardIcon } from './components/icons/CardIcon';
import { PixIcon } from './components/icons/PixIcon';
import { BoletoIcon } from './components/icons/BoletoIcon';
import { HeartIcon } from './components/icons/HeartIcon';
import { SmartphoneIcon } from './components/icons/SmartphoneIcon';
import { AccountIcon } from './components/icons/AccountIcon';
import { AssistIcon } from './components/icons/AssistIcon';
import { ShoppingIcon } from './components/icons/ShoppingIcon';

const IconsMap = {
  UnionIcon,
  FinancialIcon,
  CardIcon,
  PixIcon,
  BoletoIcon,
  HeartIcon,
  SmartphoneIcon,
  AccountIcon,
  AssistIcon,
  ShoppingIcon,
};
function SectionBenefits() {
  const isMobile = useBreakpointsMediaDown('md');

  const partitionSize = isMobile ? 2 : 1;

  const [benefitsItens, setBenefitsItens] = useState(partition(benefitsList, partitionSize));

  useEffect(() => {
    setBenefitsItens(partition(benefitsList, partitionSize));
  }, [isMobile]);

  return (
    <Box tag="section">
      <Box paddingVertical={{ xs: '12x', md: 56, lg: '16x' }}>
        <InfiniteCarousel cardSize={330} initialOffset={isMobile ? 24 : 48}>
          {benefitsItens.map((itens, index) => (
            <InfiniteCarousel.Item
              cardSize={330}
              // eslint-disable-next-line react/no-array-index-key
              key={`carousel-item-${index}`}
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              {itens.map((item, itemsIndex) => {
                const IconComponent = IconsMap[item.iconName];
                return (
                  <Box
                    key={`carousel-item-content-${item.id}`}
                    padding="6x"
                    marginBottom={{ xs: itemsIndex % 2 ? 0 : 32, md: 0 }}
                    border="1px solid"
                    borderColor="black.defaultT10"
                    borderRadius={12}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="300px"
                  >
                    <Box>
                      {IconComponent && <IconComponent aria-hidden="true" />}
                      <Typography marginTop="2x" variant="heading4">
                        {item.title}
                      </Typography>
                    </Box>
                    <Typography marginTop="4x" variant="subtitle1">
                      {item.description}
                    </Typography>
                    <Link variant="action" width="fit-content" href={item.ctaHref}>
                      {item.cta}
                    </Link>
                  </Box>
                );
              })}
            </InfiniteCarousel.Item>
          ))}
        </InfiniteCarousel>
      </Box>
    </Box>
  );
}

export default React.memo(SectionBenefits);
