import PropTypes from 'prop-types';

import { SafeAnchorOffset } from './style';

SafeAnchorOffset.defaultProps = {
  offsetTop: '-6.25rem',
};

SafeAnchorOffset.propTypes = {
  id: PropTypes.string.isRequired,
  offsetTop: PropTypes.string,
};

export default SafeAnchorOffset;
