import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionShopping() {
  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1713540338-8-dobra-home-desk-1.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1713540338-8-dobra-home-desk-1.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1713540338-8-dobra-home-desk-1.jpg' },
  }]);

  return (
    <Box
      tag="section"
      display="flex"
      backgroundColor="white.dark"
      paddingVertical="16x"
      className="SectionShopping"
    >
      <Grid gridTemplateRows={{ md: 'repeat(2, auto)', lg: '1fr' }} rowGap="24px">
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '7 / span 6' }}
          alignSelf={{ lg: 'center' }}
          gridRow={{ md: '2', lg: '1' }}
        >
          <Link
            href="/nu-shopping"
            typographyProps={{
              variant: 'heading2',
              color: 'primary',
            }}
          >
            Shopping do Nu
          </Link>
          <Typography
            variant="heading4"
            tag="p"
            color="black"
            whiteSpace="pre-line"
            marginBottom="6x"
            marginTop="16px"
          >
            Tem shopping no seu bank! Mais de 150 parceiros com ofertas para você aproveitar.
          </Typography>
          <Link variant="action" href="/nu-shopping" marginBottom="6x">
            Saiba mais
          </Link>
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 5' }}
          gridRow="1"
          alignSelf={{ lg: 'center' }}
        >
          <Image
            loading="lazy"
            alt="Foto de mulher branca sorrindo, segurando um celular, enquanto está lanchando em um mesa com alguns alimentos."
            srcSet={imageSrcSet}
          />
        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default SectionShopping;
