export const BlogCards = [
  {
    imgSrc: 'https://nubank.com.br/images-cms/1713540645-carrossel-1-tablet-1.jpg',
    imgAlt:
      'Imagem de mulher preta, segurando uma bolinha de brinquedo para cachorros enquanto seu cachorrinho olha a bolinha. A mulher está com o celular não mão com o aplicativo aberto nas Caixinhas do Nubank',
    title: 'Caixinhas',
    description:
      'Caixinhas do Nubank: tudo o que você precisa saber sobre a ferramenta que facilita os seus planos',
    ctaHref:
      'https://blog.nubank.com.br/caixinhas-do-nubank/?utm_source=website&utm_medium=blog&utm_campaign=cp-portfolio&utm_content=n-possibilidades____leia&utm_term=dg__pub__brand__all__01062023',
    ctaText: 'Ler artigo',
    id: 22,
  },
  {
    imgSrc: 'https://nubank.com.br/images-cms/1707408492-investments_talk_tablet.jpg',
    imgAlt:
      'Casal de pessoas pretas, ela está no sofa com celular na mão e ele sentado no chão com o compudador aberto em uma mesa de centro',
    title: 'Empréstimo',
    description:
      'Como fazer empréstimo no Nubank? Confira dicas para escolher o crédito certo para você ',
    ctaHref:
      'https://blog.nubank.com.br/como-fazer-emprestimo-no-nubank/?utm_source=website&utm_medium=blog&utm_campaign=cp-portfolio&utm_content=n-possibilidades____leia&utm_term=dg__pub__brand__all__01062023',
    ctaText: 'Ler artigo',
    id: 23,
  },
  {
    imgSrc: 'https://nubank.com.br/images-cms/1713540653-nu_core_2023_04-1-1-1.jpg',
    imgAlt:
      'Casal, sendo uma mulher preta e um homem branco, ele olha para ela enquanto ela segura o cartão do Nubank e olha para ele.',
    title: 'N Possibilidades',
    description: '10 situações da sua vida que o Nubank ajuda a resolver com N Possibilidades',
    ctaHref:
      'https://blog.nubank.com.br/situacoes-vida-nubank-resolve-n-possibilidades?utm_source=website&utm_medium=blog&utm_campaign=cp-portfolio&utm_content=n-possibilidades____leia&utm_term=dg__pub__brand__all__01062023',
    ctaText: 'Ler artigo',
    id: 24,
  },
];
