import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

import SafeAnchorOffset from 'components/SafeAnchorOffset/SafeAnchorOffset';

import Container from './components/Container/Container';
import HeadingBlock from './components/HeadingBlock/HeadingBlock';
import ImageBlock from './components/ImageBlock/ImageBlock';
import FormBlock from './components/FormBlock/FormBlock';

const ProductPageOpenFormSection = forwardRef(({ children, className, ...props }, ref) => (
  <Box
    ref={ref}
    className={className}
    position="relative"
    backgroundColor="white.dark"
    {...props}
  >
    <SafeAnchorOffset id="quero-ser-cliente" />
    {children}
  </Box>
));

ProductPageOpenFormSection.Container = Container;
ProductPageOpenFormSection.Heading = HeadingBlock;
ProductPageOpenFormSection.ImageBlock = ImageBlock;
ProductPageOpenFormSection.FormBlock = FormBlock;

ProductPageOpenFormSection.defaultProps = {
  className: undefined,
};

ProductPageOpenFormSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ProductPageOpenFormSection;
