import React from 'react';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import Image from '@nubank/www-latam-commons/components/Image/Image';

import { BlogCards } from './BlogCards';
import { StyledBox } from './styles/StyledBox';

function SectionBlogCards() {
  return (
    <Box tag="section" padding="64px 0">
      <Box
        paddingLeft={Grid.defaultProps.paddingLeft}
        marginBottom={{ xs: '24px', md: '40px' }}
      >
        <Typography
          variant="heading2"
          tag="h2"
          marginBottom="3x"
        >
          Fique por dentro das Novidades
        </Typography>
        <Link
          href="https://blog.nubank.com.br/"
          variant="action"
          width="fit-content"
          typographyProps={{
            color: 'primary.default',
          }}
        >
          Ir para o Blog do Nu
        </Link>
      </Box>
      <StyledBox
        display="flex"
        gridTemplateColumns={`repeat(${BlogCards.length}, auto)`}
        paddingHorizontal={Grid.defaultProps.paddingLeft}
        gridGap={{ xs: '16px', md: '40px' }}
        flex-direction="row"
        overflow="auto hidden"
      >
        {BlogCards.map(item => (
          <Box
            borderRadius="26px"
            display="flex"
            flexDirection="column"
            minWidth={{ xs: '288px', md: '400px' }}
            width={{ lg: '33%' }}
            overflow="hidden"
            backgroundColor="white"
            border="1px solid"
            borderColor="black.defaultT10"
            key={`blog-cards-${item.id}`}
          >
            <Box width="100%">
              <Image
                loading="lazy"
                objectFit="cover"
                height={{ xs: '260px', md: '330px', xl: '400px' }}
                src={item.imgSrc}
                alt={item.imgAlt}
              />
            </Box>
            <Box
              padding="24px"
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Typography
                variant="caption"
                tag="p"
                marginBottom="3x"
                color="black.light"
                fontWeight="500"
              >
                {item.title}
              </Typography>
              <Typography
                variant="heading4"
                tag="h4"
                color="black.light"
                marginBottom="4x"
              >
                {item.description}
              </Typography>
              <Link
                href={item.ctaHref}
                variant="action"
                marginTop="auto"
                width="fit-content"
                typographyProps={{
                  color: 'primary.default',
                }}
              >
                {item.ctaText}
              </Link>
            </Box>
          </Box>
        ))}
      </StyledBox>
    </Box>
  );
}

export default SectionBlogCards;
