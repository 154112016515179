import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

function Container({ children, ...props }) {
  return (
    <Box
      display="grid"
      paddingHorizontal={{ xs: '4x', md: '6x', lg: '12x' }}
      paddingVertical={{ xs: '12x', md: '20x' }}
      minHeight={{ md: 'calc(100vh - 3rem)' }}
      gridColumnGap={{ xs: '4x', md: '8x' }}
      gridRowGap={{ xs: '6x', md: '12x', lg: '0x' }}
      gridTemplateColumns={{ xs: 'repeat(4,1fr)', md: 'repeat(6,1fr)', lg: 'repeat(12,1fr)' }}
      gridTemplateRows={{ xs: 'auto 14rem 6rem 1fr', md: 'auto 1fr', lg: '1fr' }}
      justifyItems={{ xs: 'stretch' }}
      alignItems={{ lg: 'center' }}
      {...props}
    >
      {children}
    </Box>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
