export const benefitsList = [
  {
    title: 'Empréstimo',
    description:
      'Transparentes, seguros e do seu jeito. Opções de empréstimos que deixam você no controle do início ao fim.',
    iconName: 'UnionIcon',
    cta: 'Saiba mais',
    ctaHref: '/emprestimo',
    id: 11,
  },
  {
    title: 'NuConsignado',
    description:
      'O empréstimo consignado para servidores públicos federais e para aposentados e pensionistas do INSS.',
    iconName: 'UnionIcon',
    cta: 'Saiba mais',
    ctaHref: '/emprestimo/#section-consignado',
    id: 12,
  },
  {
    title: 'Investimentos',
    description:
      'Investimentos a partir de R$ 1 e opções para todos os perfis. Uma experiência fácil, segura e transparente.',
    iconName: 'FinancialIcon',
    cta: 'Saiba mais',
    ctaHref: '/investimentos',
    id: 22,
  },
  {
    title: 'Caixinhas',
    description:
      'Organize e guarde dinheiro para seus planos. Crie Caixinhas personalizadas para cada um de seus objetivos.',
    iconName: 'FinancialIcon',
    cta: 'Saiba mais',
    ctaHref: '/investimentos/#section-caixinhas',
    id: 33,
  },
  {
    title: 'Cartão de crédito',
    description:
      'Um cartão completo, moderno e gratuito  pra você. E com anuidade zero. Fique no controle da sua vida financeira.',
    iconName: 'CardIcon',
    cta: 'Saiba mais',
    ctaHref: '/cartao',
    id: 44,
  },
  {
    title: 'Pix no crédito',
    description:
      'Faça um Pix e pague com o cartão de crédito: não use o saldo da sua conta e concentre os gastos no cartão.',
    iconName: 'PixIcon',
    cta: 'Saiba mais',
    ctaHref: '/cartao',
    id: 55,
  },
  {
    title: 'Boleto no crédito',
    description:
      'Pague ou parcele em até 12 vezes seus boletos com o cartão de crédito e tenha mais controle sobre o seu dinheiro.',
    iconName: 'BoletoIcon',
    cta: 'Saiba mais',
    ctaHref: '/cartao',
    id: 66,
  },
  {
    title: 'Parcelamento de compras',
    description:
      'Parcele suas compras à vista feitas em estabelecimentos físicos e virtuais direto no app do Nubank.',
    iconName: 'CardIcon',
    cta: 'Saiba mais',
    ctaHref: '/cartao',
    id: 68,
  },
  {
    title: 'Cartão Virtual',
    description:
      'Crie novos cartões virtuais de crédito ou débito no seu app e proteja ainda mais suas compras online.',
    iconName: 'CardIcon',
    cta: 'Saiba mais',
    ctaHref: '/cartao',
    id: 67,
  },
  {
    title: 'Cartão Adicional',
    description:
      'Cartão de crédito físico adicional para ser compartilhado com outra pessoa utilizando o mesmo limite.',
    iconName: 'CardIcon',
    cta: 'Saiba mais',
    ctaHref: '/cartao',
    id: 68,
  },
  {
    title: 'Carteira Digital',
    description:
      'Armazene os dados do seu cartão de crédito nas carteiras digitais do Google Pay, Apple Pay e Samsung Pay.',
    iconName: 'CardIcon',
    cta: 'Saiba mais',
    ctaHref: '/cartao',
    id: 69,
  },
  {
    title: 'Seguro de vida',
    description:
      ' Proteja você e quem você ama por a partir de R$4 por mês. Escolha o plano que se encaixa ao seu perfil.',
    iconName: 'HeartIcon',
    cta: 'Saiba mais',
    ctaHref: '/seguros',
    id: 77,
  },
  {
    title: 'Seguro celular',
    description:
      'A mais completa proteção do mercado, seja para furto ou roubo de celular, tela quebrada ou oxidada.',
    iconName: 'SmartphoneIcon',
    cta: 'Saiba mais',
    ctaHref: '/seguros/celular',
    id: 88,
  },
  {
    title: 'Conta',
    description:
      'Receba, movimente e guarde seu dinheiro de um jeito simples e com rendimento maior que a poupança.',
    iconName: 'AccountIcon',
    cta: 'Saiba mais',
    ctaHref: '/conta',
    id: 99,
  },
  {
    title: 'Assistente de pagamento',
    description:
      'O seu assistente pessoal quando o assunto é pagar as contas do mês, desde o boleto de água até Pix para o professor de inglês.',
    iconName: 'AssistIcon',
    cta: 'Saiba mais',
    ctaHref: '/conta',
    id: 101,
  },
  {
    title: 'Shopping',
    description:
      'Um shopping que oferece tudo que você precisa: mais de 150 lojas parceiras, segurança e ótimos preços. ',
    iconName: 'ShoppingIcon',
    cta: 'Saiba mais',
    ctaHref: '/nu-shopping',
    id: 111,
  },
];
