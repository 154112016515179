import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionInvest() {
  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1713539526-5-dobra-home-mobile-1.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1713539535-5-dobra-home-tablet-1.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1713539515-5-dobra-home-desk-1.jpg' },
  }]);

  return (
    <Box
      tag="section"
      display="flex"
      backgroundColor="white.dark"
      paddingVertical="16x"
      className="SectionInvest"
    >
      <Grid rowGap="24px">
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 5' }}
          alignSelf={{ lg: 'center' }}
          gridRow={{ xs: '2', lg: '1' }}
        >
          <Link
            href="/investimentos"
            typographyProps={{
              variant: 'heading2',
              color: 'primary',
            }}
          >
            Invista seu dinheiro
          </Link>
          <Typography
            variant="heading4"
            tag="p"
            color="black"
            whiteSpace="pre-line"
            marginBottom="8px"
            marginTop="16px"
          >
            O plano é facilitar seus planos. Comece hoje com as Caixinhas do Nubank.
          </Typography>
          <Link variant="action" href="/investimentos" marginBottom="6x" marginTop="8px">
            Saiba mais
          </Link>
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '7 / span 6' }}
          gridRow="1"
          alignSelf={{ lg: 'center' }}
        >
          <Image
            loading="lazy"
            alt="Imagem de um celular com a página do aplicativo onde mostramos as caixinhas do Nubank"
            srcSet={imageSrcSet}
          />
        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default SectionInvest;
