import React from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Link from '@nubank/nuds-web/components/Link/Link';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionAccount() {
  return (
    <Box tag="section" display="grid" gridTemplateRows={{ xs: 'repeat(2, auto)', md: '1fr' }}>
      <Box
        gridRow={{ md: '1' }}
        alignContent={{ lg: 'center' }}
        backgroundColor="primary.default"
        paddingHorizontal={Grid.defaultProps.paddingLeft}
        paddingVertical={{ xs: '16x' }}
      >
        <Image
          loading="lazy"
          alt="Mulher com celular e homem com notebook na sala"
          objectFit="cover"
          height={{ xs: '220px', md: '360px', lg: '420px' }}
          srcSet={generateDPRsForCMSImage([{
            imageMobile: { url: 'https://nubank.com.br/images-cms/1730231963-en-account-3-xs.jpg' },
            imageTablet: { url: 'https://nubank.com.br/images-cms/1730841002-pix-hero-lg.jpg' },
            imageDesktop: { url: 'https://nubank.com.br/images-cms/1730841002-pix-hero-lg.jpg' },
          }])}
        />
        <Link
          href="/conta"
          typographyProps={{
            variant: 'heading2',
            color: 'white',
          }}
          marginTop="40px"
        >
          <Typography variant="heading2" marginTop="40px" marginBottom="8px">
            Conta do Nubank
          </Typography>
        </Link>
        <Typography
          variant="heading4"
          tag="p"
          color="white"
          whiteSpace="pre-line"
          marginBottom="8x"
        >
          Manter suas contas em dia nunca foi tão simples com Assistente de Pagamentos.
        </Typography>
        <Link variant="action" href="/conta" color="white">
          Saiba mais
        </Link>
      </Box>
      <Box
        gridRow={{ md: '2', lg: '1' }}
        alignContent={{ lg: 'center' }}
        backgroundColor="#2F0549"
        paddingHorizontal={Grid.defaultProps.paddingLeft}
        paddingVertical="16x"
      >
        <Image
          loading="lazy"
          alt="Retrato de um homem jovem, branco, de cabelos escuros, vestindo uma jaqueta verde com uma blusa cinza. Ele está segurando um aparelho celular com os ombros em seu ouvido, ao mesmo tempo que conserta um notebook em cima de uma mesa de madeira. Na mesa, é possível identificar um fone de ouvido roxinho e uma grande lupa com uma luz para auxiliar o processo de conserto. Ao fundo, tem-se uma prateleira desfocada."
          objectFit="cover"
          height={{ xs: '220px', md: '360px', lg: '420px' }}
          srcSet={generateDPRsForCMSImage([{
            imageMobile: { url: 'https://nubank.com.br/images-cms/1713539980-7-dobra-home-mobile-1.jpg' },
            imageTablet: { url: 'https://nubank.com.br/images-cms/1713539987-7-dobra-home-tablet-1.jpg' },
            imageDesktop: { url: 'https://nubank.com.br/images-cms/1713539972-7-dobra-home-desk-1.jpg' },
          }])}
        />
        <Link
          href="/conta-pj"
          typographyProps={{
            variant: 'heading2',
            color: 'white',
          }}
        >
          <Typography variant="heading2" marginTop="40px" marginBottom="8px">
            Conta PJ
          </Typography>
        </Link>
        <Typography
          variant="heading4"
          tag="p"
          color="white"
          whiteSpace="pre-line"
          marginBottom="8x"
        >
          Ficou mais fácil organizar o seu negócio com a conta PJ do Nubank
        </Typography>
        <Link variant="action" href="/conta-pj" color="white">
          Saiba mais
        </Link>
      </Box>
    </Box>
  );
}

export default React.memo(SectionAccount);
