import React from 'react';

export function AssistIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6371 17.5529C10.7801 18.2013 8.74928 18.1428 6.93265 17.3886C5.3031 16.7121 3.94834 15.5184 3.07176 14L5.5 14L5.5 12H1C0.447715 12 0 12.4477 0 13V19H2L2 16.0001C3.06696 17.4226 4.50177 18.545 6.16582 19.2358C8.4366 20.1785 10.9751 20.2516 13.2964 19.4411C15.6176 18.6306 17.5589 16.9934 18.7495 14.8422C19.94 12.6909 20.2963 10.1765 19.7503 7.77923C19.2042 5.38195 17.7942 3.26984 15.7895 1.84638C13.7847 0.422931 11.3259 -0.212083 8.88259 0.0626477C6.4393 0.33738 4.18286 1.5026 2.54431 3.33571C1.3565 4.66458 0.553754 6.2769 0.201999 8.00002H2.254C2.57097 6.7725 3.17772 5.62816 4.03545 4.66858C5.34628 3.20208 7.15144 2.26991 9.10607 2.05012C11.0607 1.83034 13.0278 2.33835 14.6316 3.47711C16.2353 4.61587 17.3634 6.30556 17.8002 8.22339C18.237 10.1412 17.952 12.1528 16.9996 13.8737C16.0471 15.5947 14.4941 16.9045 12.6371 17.5529Z"
        fill="#111111"
      />
      <path
        d="M10.9993 4.99978H8.99928V6.00372C7.92066 6.06549 7.01001 6.84836 6.79587 7.91904C6.53407 9.22808 7.39648 10.4974 8.70991 10.7362L11.4313 11.2311C11.6445 11.2698 11.7845 11.4758 11.742 11.6883C11.7058 11.8694 11.5468 11.9998 11.362 11.9998L7.49997 12L7.50007 14L8.99928 14V14.9998H10.9993V13.9999L11.3621 13.9998C12.5002 13.9998 13.48 13.1965 13.7032 12.0806C13.965 10.7715 13.1026 9.50212 11.7891 9.26331L9.06768 8.76851C8.85451 8.72975 8.71454 8.52373 8.75703 8.31128C8.79326 8.13016 8.95229 7.99978 9.137 7.99978H12.9998V5.99978H10.9993V4.99978Z"
        fill="#111111"
      />
    </svg>
  );
}
