import React from 'react';

export function UnionIcon(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7752 9.89099C17.9221 9.28471 18 8.65145 18 8C18 3.58172 14.4183 0 10 0C5.58172 0 2 3.58172 2 8C2 9.01667 2.18965 9.98904 2.53549 10.8837C2.16479 11.1392 1.82666 11.4462 1.53239 11.7993L1.15889 12.2475C0.410081 13.1461 0 14.2787 0 15.4484V20.0002H12.8431C14.1692 20.0002 15.441 19.4734 16.3787 18.5358L19.7071 15.2073C20.9261 13.9884 20.9261 12.0121 19.7071 10.7931C19.1677 10.2537 18.48 9.953 17.7752 9.89099ZM4.37788 10.1003C4.13357 9.44663 4 8.73891 4 8C4 4.68629 6.68629 2 10 2C13.3137 2 16 4.68629 16 8C16 8.9933 15.7586 9.93023 15.3313 10.7553C15.3184 10.7678 15.3056 10.7804 15.2929 10.7931L13.8908 12.1952C13.7711 11.7644 13.5576 11.3727 13.2739 11.0432C13.4819 10.7655 13.6317 10.4393 13.7034 10.0807C13.9652 8.7717 13.1028 7.50234 11.7894 7.26354L9.06791 6.76872C8.85474 6.72997 8.71477 6.52395 8.75726 6.31149C8.79349 6.13037 8.95252 6 9.13722 6H13V4H11V3H9V4.00391C7.92116 4.06547 7.01027 4.84841 6.7961 5.91926C6.53429 7.2283 7.39671 8.49766 8.71014 8.73646L11.4316 9.23128C11.6448 9.27003 11.7847 9.47605 11.7423 9.68851C11.706 9.86963 11.547 10 11.3623 10L5.3735 10.0002C5.03618 10.0002 4.70281 10.0343 4.37788 10.1003ZM16.7071 12.2073C17.145 11.7694 17.855 11.7694 18.2929 12.2073C18.7308 12.6452 18.7308 13.3552 18.2929 13.7931L14.9645 17.1215C14.4019 17.6842 13.6388 18.0002 12.8431 18.0002H2V15.4484C2 14.7466 2.24605 14.067 2.69534 13.5279L3.06884 13.0797C3.63882 12.3957 4.48316 12.0002 5.3735 12.0002H11C11.5523 12.0002 12 12.4479 12 13.0002C12 13.4789 11.6637 13.879 11.2145 13.9772C11.092 13.9924 10.968 14.0002 10.8431 14.0002H5.5V16.0002H11C11.187 16.0002 11.3699 15.9831 11.5474 15.9504C12.6132 15.7988 13.6086 15.3058 14.3787 14.5358L16.7071 12.2073Z"
        fill="#111111"
      />
    </svg>
  );
}
