import React from 'react';

export function FinancialIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.748 10C17.9125 9.36076 18 8.6906 18 8C18 3.58172 14.4183 0 10 0C5.58172 0 2 3.58172 2 8C2 8.6906 2.08751 9.36076 2.25203 10H0V12H1V20H19V12H20V10H17.748ZM15.6586 10H13.7181C13.9295 8.71991 13.0758 7.49743 11.7894 7.26354L9.06789 6.76872C8.85473 6.72997 8.71476 6.52395 8.75725 6.31149C8.79347 6.13037 8.9525 6 9.13721 6H13V4H11V3H8.99999V4.00391C7.92114 4.06547 7.01026 4.84841 6.79609 5.91926C6.53428 7.2283 7.3967 8.49766 8.71012 8.73646L11.4316 9.23128C11.6448 9.27003 11.7847 9.47605 11.7422 9.68851C11.706 9.86963 11.547 10 11.3623 10H4.34141C4.12031 9.37444 4 8.70127 4 8C4 4.68629 6.68629 2 10 2C13.3137 2 16 4.68629 16 8C16 8.70127 15.8797 9.37444 15.6586 10ZM17 12V18H3V12H17Z"
        fill="#111111"
      />
    </svg>
  );
}
