import React from 'react';

function CalendarIcon(props) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.87104 2H13.7428V0H15.7108V2H16.6948H18.6627V4V6V8V18V20H16.6948H2.91912H0.951172V18V8V6V4V2H2.91912H3.90309V0H5.87104V2ZM16.6948 18V8H9.80694H2.91912V18H16.6948ZM16.6948 4H2.91912V6H9.80694H16.6948V4ZM14.7268 12H10.7909V16H14.7268V12Z"
        fill="#111111"
      />
    </svg>
  );
}

export default CalendarIcon;
