import React from 'react';

export function BoletoIcon(props) {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 0V10H2V0H0Z" fill="#111111" />
      <path d="M10 0V10H12V0H10Z" fill="#111111" />
      <path d="M4 0H8V10H4V0Z" fill="#111111" />
      <path d="M14 0V10H18V0H14Z" fill="#111111" />
    </svg>
  );
}
