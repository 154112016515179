import React from 'react';

export function SmartphoneIcon(props) {
  return (
    <svg
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9 16H5V18H9V16Z" fill="#111111" />
      <path
        d="M0 4V18C0 20.2091 1.79086 22 4 22H10C12.2091 22 14 20.2091 14 18V4C14 1.79086 12.2091 0 10 0H4C1.79086 0 0 1.79086 0 4ZM4 2H10C11.1046 2 12 2.89543 12 4V18C12 19.1046 11.1046 20 10 20H4C2.89543 20 2 19.1046 2 18V4C2 2.89543 2.89543 2 4 2Z"
        fill="#111111"
      />
    </svg>
  );
}
