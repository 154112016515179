import React from 'react';

function InstallmentsIcon(props) {
  return (
    <svg
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.92027 0V2.88135C6.70092 1.04897 9.11821 0 11.8081 0C15.5515 0 18.8397 2.24672 20.5497 5.53245L18.8101 6.46755C17.4056 3.76886 14.7495 2 11.8081 2C9.3325 2 7.16054 3.09551 5.70554 5H9.34816V7H3.9363C3.39286 7 2.95232 6.55228 2.95232 6V0H4.92027Z"
        fill="#111111"
      />
      <path
        d="M14.76 14C14.76 15.6569 13.4384 17 11.8081 17C10.1778 17 8.85617 15.6569 8.85617 14C8.85617 12.3431 10.1778 11 11.8081 11C13.4384 11 14.76 12.3431 14.76 14ZM10.8241 13V15H12.7921V13H10.8241Z"
        fill="#111111"
      />
      <path
        d="M19.6799 17C21.3102 17 22.6318 15.6569 22.6318 14C22.6318 12.3431 21.3102 11 19.6799 11C18.0496 11 16.728 12.3431 16.728 14C16.728 15.6569 18.0496 17 19.6799 17ZM20.6639 13V15H18.6959V13H20.6639Z"
        fill="#111111"
      />
      <path
        d="M6.88822 14C6.88822 15.6569 5.5666 17 3.9363 17C2.306 17 0.984375 15.6569 0.984375 14C0.984375 12.3431 2.306 11 3.9363 11C5.5666 11 6.88822 12.3431 6.88822 14ZM2.95232 13V15H4.92027V13H2.95232Z"
        fill="#111111"
      />
    </svg>
  );
}

export default InstallmentsIcon;
