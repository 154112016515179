import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { topicsList } from './components/TopicsList';
import BarcodeIcon from './components/icons/BarcodeIcon';
import CalendarIcon from './components/icons/CalendarIcon';
import InstallmentsIcon from './components/icons/InstallmentsIcon';

const ICONS_MAP = {
  BarcodeIcon,
  CalendarIcon,
  InstallmentsIcon,
};

function SectionControl() {
  return (
    <Box
      tag="section"
      display="flex"
      paddingVertical={{
        lg: '24x',
        md: '16x',
        xs: '12x',
      }}
    >
      <Grid rowGap="12x">
        <Grid.Child
          gridColumn={{
            lg: '2 / span 5',
            md: '1 / span 3',
            xs: '1 / span 4',
          }}
        >
          <Typography variant="heading2">Faça mais com seu cartão de crédito</Typography>
        </Grid.Child>
        <Grid.Child
          gridColumn={{
            lg: '8 / span 4',
            md: '4 / span 3',
            xs: '1 / span 4',
          }}
        >
          {topicsList.map((item, itemIndex) => {
            const IconComponent = ICONS_MAP[item.iconName];
            return (
              <Box
                key={`control-${item.id}`}
                marginTop={itemIndex ? { xs: '8x', lg: '16x' } : null}
              >
                <IconComponent aria-hidden="true" index={item.id} />
                <Typography variant="heading4" marginBottom={{ xs: '1x', lg: '2x' }} marginTop="4x">
                  {item.title}
                </Typography>
                <Typography variant="subtitle1" marginTop="1x">
                  {item.description}
                </Typography>
              </Box>
            );
          })}
        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default React.memo(SectionControl);
