import React from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionCreditCard() {
  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1713457656-2-dobra-home-mobile.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1713457679-2-dobra-home-tablet.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1713457702-2-dobra-home-desk.jpg' },
  }]);

  return (
    <Box
      tag="section"
      display="flex"
      backgroundColor="white.dark"
      paddingVertical="16x"
      className="SectionShopping"
    >
      <Grid rowGap="6x">
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 5' }}
          alignSelf={{ lg: 'center' }}
          gridRow={{ md: '1' }}
        >
          <Link
            href="/cartao"
            typographyProps={{
              variant: 'heading2',
              color: 'primary.default',
            }}
          >
            Conheça nosso Cartão de Crédito
          </Link>
          <Typography
            variant="heading4"
            tag="p"
            color="black.default"
            whiteSpace="pre-line"
            marginBottom="4x"
            marginTop="4x"
          >
            Pode chamar ele de roxinho. Além disso, pode chamar ele de moderno, gratuito e prático
            também.
          </Typography>
          <Link
            variant="action"
            href="/cartao"
            marginBottom="6x"
            typographyProps={{
              color: 'primary.default',
            }}
          >
            Saiba mais
          </Link>
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '7 / span 6' }}
          gridRow={{ xs: '1', md: '2', lg: '1' }}
          alignSelf={{ lg: 'center' }}
        >
          <Image
            loading="lazy"
            alt="Foto de uma máquina de cartão em cima de uma mesa de madeira. Uma mão feminina está aproximando o cartão Nubank da máquina, representando a função contactless de pagamento."
            title="Função contactless Nubank"
            srcSet={imageSrcSet}
          />
        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default React.memo(SectionCreditCard);
