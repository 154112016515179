import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

function FormBlock({ children, ...props }) {
  return (
    <Box
      gridColumn={{ xs: '1 / span 4', md: '4 / span 3', lg: '9 / span 4' }}
      gridRow={{ xs: '3 / span 2', md: '2', lg: '1' }}
      alignSelf={{ md: 'flex-end', lg: 'auto' }}
      justifySelf={{ xs: 'center', md: 'flex-end' }}
      {...props}
    >
      {children}
    </Box>
  );
}

FormBlock.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormBlock;
