import React from 'react';
import PropTypes from 'prop-types';

import websitePage, { headerTypes } from 'hoc/websitePage/websitePage';
import withApplicationFormDrawer from 'hoc/withApplicationFormDrawer/withApplicationFormDrawer';

import SectionForm from './patterns/SectionForm/SectionForm';
import SectionAboutUs from './patterns/SectionAboutUs/SectionAboutUs';
import SectionAccount from './patterns/SectionAccount/SectionAccount';
import SectionBlogCards from './patterns/SectionBlogCards/SectionBlogCards';
import SectionBenefits from './patterns/SectionBenefits/SectionBenefits';
import SectionBenefitsCards from './patterns/SectionBenefitsCards/SectionBenefitsCards';
import SectionBlog from './patterns/SectionBlog/SectionBlog';
import SectionControl from './patterns/SectionControl/SectionControl';
import SectionCreditCard from './patterns/SectionCreditCard/SectionCreditCard';
import SectionInvest from './patterns/SectionInvest/SectionInvest';
import SectionNuCommunity from './patterns/SectionNuCommunity/SectionNuCommunity';
import SectionShopping from './patterns/SectionShopping/SectionShopping';
import SectionUltraviolet from './patterns/SectionUltraviolet/SectionUltraviolet';

function Home({ onFormVisibilityChange }) {
  const EXPERIMENT_CARDS = [
    SectionBenefits,
    SectionCreditCard,
    SectionUltraviolet,
    SectionControl,
    SectionBenefitsCards,
    SectionInvest,
    SectionAccount,
    SectionShopping,
    SectionBlogCards,
    SectionAboutUs,
    SectionNuCommunity,
    SectionBlog,
  ];

  return (
    <>
      <SectionForm
        onFormVisibilityChange={onFormVisibilityChange}
      />
      {EXPERIMENT_CARDS.map(CardComponent => (
        <CardComponent />
      ))}
    </>
  );
}

Home.propTypes = {
  onFormVisibilityChange: PropTypes.func.isRequired,
};

export default withApplicationFormDrawer(
  websitePage({
    routeKey: 'HOME',
    header: headerTypes.PRODUCT_PAGE,
  })(Home),
);
