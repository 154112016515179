import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import { themeSpace } from 'styles/themeUtils';

const handleOpenForm = css`
  background-color: ${nuDSColor('white')};
  border-radius: 1rem;
`;

const handleShortForm = css`
  max-width: 21rem;
  height: auto;
  z-index: 2;
  ${breakpointsMedia({
    xs: css`
      margin: 0 auto;
      `,
    md: css`
      margin-right: 0;
    `,
  })}
`;

export const Content = styled.div`
  padding: ${themeSpace(2)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  ${(props => props.isOpenForm && handleOpenForm)};
  ${(props => props.isShortForm && handleShortForm)};
`;

Content.displayName = 'Content';

Content.propTypes = {
  isOpenForm: PropTypes.bool,
};

Content.defaultProps = {
  isOpenForm: false,
};
