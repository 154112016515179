export const BenefitsCards = [
  {
    imgSrc: 'https://nubank.com.br/images-cms/1713539009-carrossel-1-mobile-1.jpg',
    imgAlt:
      'Duas pessoas brancas, sendo um homem e uma mulher, sentados em uma mesa enquanto observam juntos papeis de contas',
    title: 'Emprestimos',
    description: 'Transparentes, seguros e do seu jeito: da simulação até a última parcela',
    ctaHref: '/emprestimo',
    ctaText: 'Saiba mais',
    id: 77,
  },
  {
    imgSrc: 'https://nubank.com.br/images-cms/1713536292-5-dobra-conta-mobile-1.jpg',
    imgAlt: '3 pessoas pretas sentadas na grama, sendo um casal e seu filho menor e um cachorro branco e bege.',
    title: 'Seguro de vida',
    description: 'Seguro para proteger você e quem você mais ama: agora e no futuro.',
    ctaHref: '/seguros',
    ctaText: 'Saiba mais',
    id: 78,
  },
  {
    imgSrc: 'https://nubank.com.br/images-cms/1713533615-hero-tablet-indicacao-1.jpg',
    imgAlt: 'Homem branco com seu celular na mão, ele está sorrindo olhando para o céu.',
    title: 'Seguro celular',
    description: 'Para você não ficar sem curtir seu celular e tudo que você pode fazer com ele.',
    ctaHref: '/seguros/celular',
    ctaText: 'Saiba mais',
    id: 79,
  },
];
