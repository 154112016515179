import React from 'react';

function BarcodeIcon(props) {
  return (
    <svg
      width="19"
      height="10"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.951172 0V10H2.91912V0H0.951172Z" fill="#111111" />
      <path d="M10.7909 0V10H12.7589V0H10.7909Z" fill="#111111" />
      <path d="M4.88707 0H8.82297V10H4.88707V0Z" fill="#111111" />
      <path d="M14.7268 0V10H18.6627V0H14.7268Z" fill="#111111" />
    </svg>
  );
}

export default BarcodeIcon;
