import React from 'react';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionNuCommunity() {
  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1713546986-7-dobra-conta-mobile-1.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1713546995-7-dobra-conta-tablet-1.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1713546972-7-dobra-conta-desk-1.jpg' },
  }]);
  const optimizedImageSrcSet = {
    ...imageSrcSet,
    lg: {
      '1x': `${imageSrcSet.lg['1x']}?w=1920`,
      '2x': `${imageSrcSet.lg['2x']}?w=1920`,
      '3x': `${imageSrcSet.lg['3x']}?w=1920`,
    },
  };

  return (
    <Box
      tag="section"
      className="SectionNuCommunity"
      backgroundImage={optimizedImageSrcSet}
      backgroundPosition="center"
      backgroundSize="cover"
      minHeight={{ xs: '456px', md: '576px', lg: 'calc(100vh - 56px)' }}
      display="flex"
      alignItens="center"
    >
      <Grid>
        <Grid.Child
          gridColumn={{
            xs: '1 / -1',
            md: '1 / span 4',
            lg: '3 / span 6',
            xl: '3 / span 5',
          }}
          gridRow="1 / -1"
          alignSelf={{ lg: 'center' }}
        >
          <Box paddingVertical={{ xs: '6x', md: '10x' }}>
            <Typography
              variant="heading2"
              color="white.default"
              marginBottom="2x"
              whiteSpace="pre-line"
            >
              NuCommunity
            </Typography>
            <Typography
              variant="heading2"
              color="white.default"
              marginBottom="8x"
              whiteSpace="pre-line"
            >
              A comunidade oficial do Nubank para você tirar dúvidas e
              compartilhar ideias.
            </Typography>
            <Link
              variant="action"
              href="https://comunidade.nubank.com.br/?utm_content=www_hero"
              typographyProps={{
                color: 'white.default',
              }}
            >
              Faça parte
            </Link>
          </Box>
        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default React.memo(SectionNuCommunity);
