import React from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionUltraviolet() {
  const imageSrcSet = generateDPRsForCMSImage([
    {
      imageMobile: { url: 'https://nubank.com.br/images-cms/1704391203-uv_card_xs.jpg' },
      imageTablet: { url: 'https://nubank.com.br/images-cms/1704391114-uv_card_md.jpg' },
      imageDesktop: { url: 'https://nubank.com.br/images-cms/1704391197-uv_card_lg.jpg' },
    },
  ]);

  return (
    <Box
      tag="section"
      display="flex"
      backgroundColor="#2F0549"
      paddingVertical="16x"
      className="SectionUltraviolet"
    >
      <Grid gridTemplateRows={{ md: 'repeat(2, auto)', lg: '1fr' }} rowGap="6x">
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '7 / span 6' }}
          alignSelf={{ lg: 'center' }}
          gridRow={{ xs: '2', md: '1' }}
        >
          <Link
            href="/ultravioleta"
            typographyProps={{
              variant: 'heading2',
              color: 'white.default',
              whiteSpace: 'pre-line',
            }}
          >
            Nubank Ultravioleta
          </Link>
          <Typography
            marginTop="8x"
            variant="heading4"
            tag="p"
            color="white.default"
            whiteSpace="pre-line"
            marginBottom="8x"
          >
            A experiência exclusiva para clientes de alta renda.
          </Typography>
          <Link
            variant="action"
            href="/ultravioleta"
            typographyProps={{
              color: 'white.default',
            }}
            marginBottom="2x"
          >
            Saiba mais
          </Link>
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 5' }}
          gridRow={{ xs: '1', md: '2', lg: '1' }}
          alignSelf={{ lg: 'center' }}
        >
          <Image
            loading="lazy"
            alt="Mulher sorrindo segurando um cartão nubank ultravioleta"
            srcSet={imageSrcSet}
          />
        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default React.memo(SectionUltraviolet);
