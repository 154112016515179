import React from 'react';

export function HeartIcon(props) {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0004 5.5778L12.6624 2.91574C13.9133 1.66489 15.9519 1.70003 17.1589 2.99325C18.3084 4.22493 18.2753 6.14599 17.084 7.33732L10.0003 14.421L2.91672 7.33732C1.72539 6.14599 1.69228 4.22493 2.84184 2.99325C4.04884 1.70003 6.08744 1.6649 7.33829 2.91575L10.0004 5.5778ZM10.0003 17.2494L18.4982 8.75154C20.4516 6.79813 20.5059 3.64818 18.621 1.62861C16.6419 -0.49186 13.2992 -0.549477 11.2482 1.50153L10.0003 2.74937L8.75251 1.50153C6.7015 -0.549471 3.35884 -0.491856 1.37973 1.62861C-0.505197 3.64818 -0.450903 6.79813 1.50251 8.75154L10.0003 17.2494Z"
        fill="#111111"
      />
    </svg>
  );
}
